/**
 * Feedback模块接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const feedback = {
    // Feedback查询
    queryFeedback(data) {
        return request({
            url: '/api/info/feedback/queryFeedback',
            method: 'post',
            data: data
        });
    },
    // 删除Feedback
    delFeedback(id) {
        return request({
            url: '/api/info/feedback/delFeedback/'+id,
            method: 'get'
        });
    },
    //保存留言信息
    addFeedback(data){
        return request({
            url:'/api/info/feedback/addFeedback',
            method:'post',
            data:data,
        });
    },
}

export default feedback;