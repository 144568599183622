<template>
 <Head :data="data"/>
  <div style="padding-top:46px; ">
    <van-divider />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
            v-model="ruleForm.linkman"
            name="姓名"
            label="姓名"
            placeholder="输入姓名"
            required
            :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field
            v-model="ruleForm.product"
            name="产品"
            label="产品"
            placeholder="输入产品"
            required
            :rules="[{ required: true, message: '请输入产品' }]"
        />
        <van-field
            v-model="ruleForm.content"
            rows="3"
            autosize
            type="textarea"
            name="您的留言"
            label="您的留言"
            placeholder="您的留言"
            maxlength="100"
            show-word-limit
            required
            :rules="[{ required: true, message: '请输入您的留言内容' }]"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import  {ref} from 'vue';
import { Toast } from 'vant';
import 'vant/lib/index.css';

import Head from '@/components/h5/Head'

import feedbackApi from "@/api/info/feedback";

export default {
  components:{
    Head
  },
  setup(){

    const data = ref({
      sign: true,
      title: '信息留言',
      back: true
    });

    const ruleForm = ref({
      linkman:'',
      product:'',
      content:''
    })

    // 提交留言
    const onSubmit = () => {
      /*Toast.loading({
        message: '正在提交...',
        forbidClick: true,
        duration:5000,
      });*/

      feedbackApi.addFeedback(ruleForm.value).then((response) => {
        if (response.data.code == 1) {
          Toast.success('提交成功，感谢您的留言！');
          ruleForm.value = {};
        } else {
          Toast.fail('很抱歉，提交失败，请重新提交！');
        }
      });
    };


    return{
      data,
      ruleForm,
      onSubmit,
    }
  }
}
</script>

<style scoped>
.van-divider{
  margin: 5px;
}
</style>